@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'
@import './prism-theme.styl'

body
    background-color $grey-1
    overscroll-behavior-y: contain

.bg-code
    background-color $code-color

.doc-token
    white-space nowrap
    background lighten($primary, 85%)
    color $dark-primary
    font-family inherit
    border-radius $generic-border-radius
    padding 1px 5px
    margin 0

blockquote .doc-token
    background lighten($grey, 60%)
    color $grey-9

.doc-note
    background-color $grey-3
    border-radius $generic-border-radius
    margin 16px 0
    padding 16px 24px
    font-size 1em
    border-width 0 5px 0
    border-style solid
    border-color $grey
    letter-spacing .5px

    .doc-token
        color $grey-9

    > p
        margin-bottom 0

    &--tip
        background-color lighten($positive, 75%)
        border-color $positive
        .doc-token
            background lighten($positive, 55%)
        .doc-link
            color darken($positive, 35%)

    &--warning
        background-color lighten($warning, 50%)
        border-color $warning
        .doc-token
            background lighten($warning, 15%)
        .doc-link
            color darken($warning, 45%)

    &--danger
        background-color lighten($negative, 80%)
        border-color $negative
        .doc-token
            background lighten($negative, 65%)
        .doc-link
            color darken($negative, 25%)

    &__title
        font-weight 500

.doc-heading
    color $primary
//cursor pointer
//
//&:after
//  content ' #'
//  opacity 0
//&:hover:after
//  opacity 1

.doc-h1
    font-size 2rem
    line-height 2rem
    padding 1rem 0
    font-weight 500
    margin 0 0 2rem

.doc-h2
    font-size 1.5rem
    line-height 1.5rem
    padding 0.5rem 0
    font-weight 500
    border-bottom 1px solid #ccc
    margin 4rem 0 1.5rem

.doc-h3
    font-size 1.1rem
    line-height 1.1rem
    padding 0.45rem 0
    margin 2rem 0 1rem

.doc-h4
    font-size 1rem
    line-height 1rem
    padding 0.25rem 0
    margin 1.5rem 0

.doc-h5
    font-size 0.9rem
    margin 1.5rem 0

.doc-h6
    font-size 0.8rem
    margin 1.5rem 0

.doc-img
    max-width 100%

.doc-page-listing
    .q-icon
        font-size 20px
        margin-right 8px
        color $primary

//  eigen css

.of-text-h6
    font-size 1.1rem
    margin 0.3rem 0




.of-page-column
    max-width: 1000px
    margin-left: auto
    margin-right: auto


//.of-page-column-2
//    max-width: 1000px
//    margin-left: auto
//    margin-right: auto

.of-green-border
    border: 2px #056839 solid;
    border-radius: 3px;


@font-face
    font-family: "Lobster script=latin rev=1"
    src: url(./fonts/Lobster-Regular.ttf)

.my-font
    font-family: 'Lobster script=latin rev=1'

/*.of-mobile-header-fix
    height: calc(100vh - 50px);*/

.of-mobile-header-fix
    height: calc(100vh);

.of-other-header-fix
    height: calc(100vh - 51px);


.ol-zoom  {
    background-color: green !important;
}

.under {
    text-decoration: underline red !important;
}

.over {
    text-decoration: wavy overline lime !important;
}

.my-center {
    margin: auto;
}

//.style2
//    display:none




// app global css

//a
//    /*border: solid 1px #f00;*/
//    color: grey;
//
//
//.q-item--active,.q-item.q-router-link--active {
//    color: #027be3;
//    color: var(--q-color-primary)
// }
//.custom-header-nodge-fix {
//    padding-top: constant(safe-area-inset-top);
//    padding-top: env(safe-area-inset-top);
//}
//
//
//.style1 {
//    background: url(https://gmslikeen.Xena.org/assets/images/bg-beach.jpg) no-repeat center center fixed;
//    -webkit-background-size: cover;
//    -moz-background-size: cover;
//    -o-background-size: cover;
//    background-size: cover;
//}
