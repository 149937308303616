.doc-code,
.doc-code__inner {
  margin: 0;
  position: relative;
  font-size: 12px;
  color: #424242;
  background-color: #fdfdfd;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  hyphens: none;
}
.doc-code {
  overflow: visible;
  padding: 0;
}
.doc-code__inner {
  background-image: linear-gradient(transparent 50%, #f1fdf7 50%);
  background-size: 3em 3em;
  background-origin: content-box;
  background-attachment: local;
  max-height: inherit;
  height: inherit;
  padding: 1em;
  display: block;
  overflow: auto;
}
.doc-code__inner--prerendered {
  border-width: 1px 1px 1px 3px;
  border-style: solid;
  border-color: rgba(0,0,0,0.12);
  border-left-color: #0f6939;
  border-radius: 4px;
}
.token.comment,
.token.block-comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: #7d8b99;
}
.token.punctuation {
  color: #5f6364;
}
.token.important {
  font-weight: normal;
}
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.function-name,
.token.constant,
.token.symbol,
.token.deleted {
  color: #c92c2c;
}
.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.function,
.token.builtin,
.token.inserted {
  color: #2f9c0a;
}
.token.operator,
.token.entity,
.token.url,
.token.variable {
  color: #a67f59;
  background: rgba(255,255,255,0.5);
}
.token.atrule,
.token.attr-value,
.token.keyword,
.token.class-name {
  color: #1990b8;
}
.token.regex,
.token.important {
  color: #e90;
}
.language-css .token.string,
.style .token.string {
  color: #a67f59;
  background: rgba(255,255,255,0.5);
}
.namespace {
  opacity: 0.7;
}
.token.tab:not(:empty):before,
.token.cr:before,
.token.lf:before {
  color: #e0d7d1;
}
body {
  background-color: #fafafa;
  overscroll-behavior-y: contain;
}
.bg-code {
  background-color: #f1fdf7;
}
.doc-token {
  white-space: nowrap;
  background: #ccf8e0;
  color: #093f22;
  font-family: inherit;
  border-radius: 4px;
  padding: 1px 5px;
  margin: 0;
}
blockquote .doc-token {
  background: #d8d8d8;
  color: #424242;
}
.doc-note {
  background-color: #eee;
  border-radius: 4px;
  margin: 16px 0;
  padding: 16px 24px;
  font-size: 1em;
  border-width: 0 5px 0;
  border-style: solid;
  border-color: #9e9e9e;
  letter-spacing: 0.5px;
}
.doc-note .doc-token {
  color: #424242;
}
.doc-note > p {
  margin-bottom: 0;
}
.doc-note--tip {
  background-color: #c1f4cd;
  border-color: #21ba45;
}
.doc-note--tip .doc-token {
  background: #90eba5;
}
.doc-note--tip .doc-link {
  color: #15792d;
}
.doc-note--warning {
  background-color: #f9e09b;
  border-color: #f2c037;
}
.doc-note--warning .doc-token {
  background: #f4c955;
}
.doc-note--warning .doc-link {
  color: #99730a;
}
.doc-note--danger {
  background-color: #ffc0c6;
  border-color: #c10015;
}
.doc-note--danger .doc-token {
  background: #ff909c;
}
.doc-note--danger .doc-link {
  color: #910010;
}
.doc-note__title {
  font-weight: 500;
}
.doc-heading {
  color: #0f6939;
}
.doc-h1 {
  font-size: 2rem;
  line-height: 2rem;
  padding: 1rem 0;
  font-weight: 500;
  margin: 0 0 2rem;
}
.doc-h2 {
  font-size: 1.5rem;
  line-height: 1.5rem;
  padding: 0.5rem 0;
  font-weight: 500;
  border-bottom: 1px solid #ccc;
  margin: 4rem 0 1.5rem;
}
.doc-h3 {
  font-size: 1.1rem;
  line-height: 1.1rem;
  padding: 0.45rem 0;
  margin: 2rem 0 1rem;
}
.doc-h4 {
  font-size: 1rem;
  line-height: 1rem;
  padding: 0.25rem 0;
  margin: 1.5rem 0;
}
.doc-h5 {
  font-size: 0.9rem;
  margin: 1.5rem 0;
}
.doc-h6 {
  font-size: 0.8rem;
  margin: 1.5rem 0;
}
.doc-img {
  max-width: 100%;
}
.doc-page-listing .q-icon {
  font-size: 20px;
  margin-right: 8px;
  color: #0f6939;
}
.of-text-h6 {
  font-size: 1.1rem;
  margin: 0.3rem 0;
}
.of-page-column {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}
.of-green-border {
  border: 2px #056839 solid;
  border-radius: 3px;
}
@font-face {
  font-family: "Lobster script=latin rev=1";
  src: url("./fonts/Lobster-Regular.ttf");
}
.my-font {
  font-family: 'Lobster script=latin rev=1';
}
/*.of-mobile-header-fix
    height: calc(100vh - 50px);*/
.of-mobile-header-fix {
  height: calc(100vh);
}
.of-other-header-fix {
  height: calc(100vh - 51px);
}
.ol-zoom {
  background-color: #008000 !important;
}
.under {
  text-decoration: underline #f00 !important;
}
.over {
  text-decoration: wavy overline #0f0 !important;
}
.my-center {
  margin: auto;
}
/*# sourceMappingURL=src/css/app.css.map */